import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const BlueBlock = styled.div`
  width: 100%;
  height: 75px;
  background: #13308e;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    color: #fff;
    font-size: clamp(18px, 3vw, 26px);
    margin: 0 auto;
  }

  h3 {
    color: #fff;
    font-size: clamp(18px, 3vw, 26px);
    margin: 0 auto 5px;
  }
  h4 {
    color: #fff;
    font-size: clamp(16px, 3vw, 24px);
    margin: 0 auto;
    font-weight: 300;
  }
`;

const TyContainer = styled.div`
  width: 100%;
`;

const HeaderContainer = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: clamp(20px, 3vw, 28px);
    // margin: 0;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;

const OrderedListContainer = styled.div`
  width: 100%;
  padding: 20px 40px;
`;

const LowerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 20px 40px;
  align-items: center;

  ul {
    margin-bottom: 0;
    list-style: none;

    li {
      &:before {
        content: '✓';
        padding: 0 10px 0 0;
        color: #2e3a59;
        font-weight: 800;
      }
    }
  }
  a {
    width: 100%;
    max-width: 300px;
  }
  button {
    background: #d87026;
    width: 100%;
    max-width: 300px;
    padding: 10px 0;
    font-size: clamp(16px, 3vw, 26px);
    font-weight: 600;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    span {
      font-weight: 300;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.9;
      box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
        rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
        rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
        rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
        rgba(0, 0, 0, 0.09) 0px 32px 16px;
    }
  }

  @media (max-width: 801px) {
    flex-direction: column;
  }
`;

const LowerLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 20px;
  @media (max-width: 801px) {
    flex-direction: column;
  }
`;
const ThreeLogos = styled.div`
  width: 49%;
  display: flex;
  justify-content: space-around;
  @media (max-width: 801px) {
    width: 100%;
    margin-top: 5px;
  }
`;

const SmallLogoContainer = styled.div`
  width: 100%;
  max-width: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    max-width: 65px;
    img {
      width: 100%;
      margin: 0;
    }
  }

  p {
    margin: 5px auto 0;
    text-align: center;
    font-weight: 600;
  }
`;

const EgrmcloThankyou = () => {
  return (
    <TyContainer>
      <BlueBlock />
      <HeaderContainer>
        <h1>You've been connected with our featured provider, GRMC Lending</h1>
        <LogoContainer>
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/GRMCLogoBlack.png"
            alt="GRMC Lending"
          />
        </LogoContainer>
      </HeaderContainer>
      <BlueBlock>
        <h2>What Happens Next?</h2>
      </BlueBlock>
      <OrderedListContainer>
        <ol>
          <li>
            You will receive a text shortly, You can click to call on the number
            provided, or you reply to schedule an appointment.
          </li>
          <li>
            A GRMC Loan Officer will walk you through you best loan options for
            your needs.
          </li>
          <li>
            Secure your new loan terms in minutes and get the savings started.
          </li>
        </ol>
      </OrderedListContainer>
      <BlueBlock>
        <h3>Have Questions?</h3>
        <h4>Your GRMC Lending Loan Officer can Help.</h4>
      </BlueBlock>
      <LowerContainer>
        <ul>
          <li>Can I lower my monthly mortgage payment?</li>
          <li>Should I switch my term from a 15 to a 30 year?</li>
          <li>Can I consolidate my credit card debt with a refinance?</li>
          <li>Can I use my funds from my refinance for home improvements?</li>
        </ul>
        <a href="tel:8447802672">
          <button type="button">
            Call Us <br /> <span>(844) 780-1672</span>
          </button>
        </a>
      </LowerContainer>
      <LowerLogoContainer>
        <ThreeLogos>
          <SmallLogoContainer>
            <div className="imageContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/Refi.png"
                alt="Refi"
              />
            </div>
            <p>Refinancing</p>
          </SmallLogoContainer>
          <SmallLogoContainer>
            <div className="imageContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/fha.png"
                alt="FHA"
              />
            </div>
            <p>FHA Loan</p>
          </SmallLogoContainer>
          <SmallLogoContainer>
            <div className="imageContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/Heloc.png"
                alt="HELOC"
              />
            </div>
            <p>HELOC</p>
          </SmallLogoContainer>
        </ThreeLogos>
        <ThreeLogos>
          <SmallLogoContainer>
            <div className="imageContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/PowerBuyer.png"
                alt="PowerBuyer"
              />
            </div>
            <p>Power Buyer</p>
          </SmallLogoContainer>
          <SmallLogoContainer>
            <div className="imageContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/VA.png"
                alt="VA"
              />
            </div>
            <p>VA Loan</p>
          </SmallLogoContainer>
          <SmallLogoContainer>
            <div className="imageContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/Conventional.png"
                alt="conventional"
              />
            </div>
            <p>Conventional Fixed</p>
          </SmallLogoContainer>
        </ThreeLogos>
      </LowerLogoContainer>
    </TyContainer>
  );
};

export default withThankyouWrapper(EgrmcloThankyou);
